// import store from "@/store/store";
// import router from "@/router";
// import {ElNotification} from "element-plus";
// import i18n from "@/i18n";

export default {

    sendRequest: async function (fetchData) {
        try {

            // const request = {
            //     method: 'post',
            //     path: 'user/sign-in/signup',
            //     headers: {
            //         'Content-Type': 'application/x-www-form-urlencoded'
            //     },
            //     properties: {
            //         email: user.email,
            //         password: user.password
            //     }
            // }


            let url = new URL(process.env.VUE_APP_SERVER + fetchData.path);
            // if (fetchData.method === 'GET' || fetchData.method === 'get') {
            //     for (let nameParam in fetchData.params) {
            //         url.searchParams.set(nameParam, fetchData.params[nameParam]);
            //     }
            // }
            for (let nameParam in fetchData.params) {
                url.searchParams.set(nameParam, fetchData.params[nameParam]);
            }

            fetchData.credentials = "include"
            fetchData.mode = 'cors'

            if (!fetchData.headers) {
                fetchData.headers = {}
            }

            if ((fetchData.method === 'POST' || fetchData.method === 'post')  && (fetchData.headers['Content-Type'] === undefined)) {
                fetchData.headers['Content-Type'] = 'application/json'
            }

            if (
                (fetchData.method === 'POST' || fetchData.method === 'post') &&
                fetchData.headers['Content-Type'] === 'application/json')
            {
                fetchData.body = JSON.stringify(fetchData.body)
            }

            let response = await fetch(url.toString(), fetchData);
            if (!response.ok) {
                return new Promise((resolve, reject) => {
                    reject({server: { answer: response.status}})
                });
                //
                // throw new Error('Service HTTP error');
            }

            if (fetchData.options?.blob) {
                const url = window.URL.createObjectURL(await response.blob());
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = fetchData.options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                return
            }

            let obj = await response.json()
            // if (obj.status === 'success') {
            //     return obj.data
            // }

            return new Promise((resolve, reject) => {
                if (obj.status === 'success') {
                    resolve(obj.data)
                } else {
                    reject(obj.data)
                }
            });

        } catch (err) {
            console.log(err);
            return new Promise((resolve, reject) => {

                reject({server: { internet: false }})
            });
        }
    }
}
