<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="
      this.$store.state.theme.isRTL ? 'top-0 position-sticky z-index-sticky' : ''
    "
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs textWhite="text-white" />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.theme.isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="this.$store.state.theme.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
<!--          <div class="input-group">-->
<!--            <span class="input-group-text text-body">-->
<!--              <i class="fas fa-search" aria-hidden="true"></i>-->
<!--            </span>-->
<!--            <input-->
<!--              type="text"-->
<!--              class="form-control"-->
<!--              :placeholder="-->
<!--                this.$store.state.theme.isRTL ? 'أكتب هنا...' : 'Type here...'-->
<!--              "-->
<!--            />-->
<!--          </div>-->
        </div>
        <ul class="navbar-nav justify-content-end">
          <li
              v-if="!$store.state.init.user?.email"
              class="nav-item dropdown d-flex align-items-center mr-4"
              :class="this.$store.state.theme.isRTL ? 'ps-2' : 'pe-2'"
          >
            <router-link
                :to="{ name: 'Profile' }"
                class="btn btn-warning btn-sm text-white mb-0"
            >
              <i class="fas fa-at me-1"></i>
              {{$t("Profile.NeedEmailBtn")}}
            </router-link>
          </li>
          <li

              class="nav-item dropdown d-flex align-items-center mr-4"
              :class="this.$store.state.theme.isRTL ? 'ps-2' : 'pe-2'"
          >
            <Lang />
          </li>
          <li
              v-if="$store.state.init.tariffs && $store.state.init.tariffs[$store.state.init.user?.tariff]?.type === 'balance'"
              class="nav-item dropdown d-flex align-items-center mr-4"
              :class="this.$store.state.theme.isRTL ? 'ps-2' : 'pe-2'"
          >
            <router-link
                :to="{ name: 'Profile' }"
                class="p-0 nav-link text-white"
            >
              <i class="fas fa-wallet me-1"></i>
              {{$store.state.init.user.bill_sum}} {{$store.state.init.user.currency}}
            </router-link>
          </li>

          <li
              class="nav-item dropdown d-flex align-items-center mr-4"
              :class="this.$store.state.theme.isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
                href="https://graspil.com/ru/blog"
                class="p-0 nav-link text-white"
                target="_blank"
            >
              <i class="fas fa-book"></i>
              {{$t('Menu.Blog')}}
            </a>
          </li>

          <WhatsNew />
          <li class="nav-item d-flex align-items-center">

            <div class="dropdown ">
              <a class="px-0 nav-link font-weight-bold text-white" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <span>
                  <i class="fa fa-user" :class="this.$store.state.theme.isRTL ? 'ms-sm-2' : 'me-sm-2'"></i>
                 {{$t('User.Profile')}}
                </span>
              </a>

              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                <li>
                  <router-link
                      :to="{ name: 'Profile' }"
                      class="dropdown-item"
                  >
                    <i
                        class="fas fa-user"
                        :class="this.$store.state.theme.isRTL ? 'ms-sm-2' : 'me-sm-2'"
                    ></i>
                    <span class="d-sm-inline d-none">{{$t('User.Profile')}}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                      :to="{ name: 'Profile' }"
                      class="dropdown-item"
                  >
                    <i
                        class="fas fa-wallet"
                        :class="this.$store.state.theme.isRTL ? 'ms-sm-2' : 'me-sm-2'"
                    ></i>
                    <span class="d-sm-inline d-none">{{$t('Menu.Billing')}}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'Bots' }"
                    class="dropdown-item"
                  >
                    <i
                      class="fas fa-robot"
                      :class="this.$store.state.theme.isRTL ? 'ms-sm-2' : 'me-sm-2'"
                    ></i>
                    <span class="d-sm-inline d-none">{{$t('Menu.Bots')}}</span>
                  </router-link>
                </li>
                <li>
                  <hr class="mt-0 mb-0 horizontal dark" />
                  <a class="dropdown-item" href="#" @click.stop="logout">
                    <i
                        class="fa fa-sign-out-alt"
                        :class="this.$store.state.theme.isRTL ? 'ms-sm-2' : 'me-sm-2'"
                    ></i>
                    {{$t('User.Logout')}}
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
<!--          <li class="px-3 nav-item d-flex align-items-center">-->
<!--            <a class="p-0 nav-link text-white" @click="toggleConfigurator">-->
<!--              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li-->
<!--            class="nav-item dropdown d-flex align-items-center"-->
<!--            :class="this.$store.state.theme.isRTL ? 'ps-2' : 'pe-2'"-->
<!--          >-->
<!--            <a-->
<!--              href="#"-->
<!--              class="p-0 nav-link text-white"-->
<!--              :class="[showMenu ? 'show' : '']"-->
<!--              id="dropdownMenuButton"-->
<!--              data-bs-toggle="dropdown"-->
<!--              aria-expanded="false"-->
<!--              @click="showMenu = !showMenu"-->
<!--            >-->
<!--              <i class="cursor-pointer fa fa-bell"></i>-->
<!--            </a>-->
<!--            <ul-->
<!--              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"-->
<!--              :class="showMenu ? 'show' : ''"-->
<!--              aria-labelledby="dropdownMenuButton"-->
<!--            >-->
<!--              <li class="mb-2">-->
<!--                <a class="dropdown-item border-radius-md" href="javascript:;">-->
<!--                  <div class="py-1 d-flex">-->
<!--                    <div class="my-auto">-->
<!--                      <img-->
<!--                        src="../../assets/img/team-2.jpg"-->
<!--                        class="avatar avatar-sm me-3"-->
<!--                        alt="user image"-->
<!--                      />-->
<!--                    </div>-->
<!--                    <div class="d-flex flex-column justify-content-center">-->
<!--                      <h6 class="mb-1 text-sm font-weight-normal">-->
<!--                        <span class="font-weight-bold">New message</span> from-->
<!--                        Laur-->
<!--                      </h6>-->
<!--                      <p class="mb-0 text-xs text-secondary">-->
<!--                        <i class="fa fa-clock me-1"></i>-->
<!--                        13 minutes ago-->
<!--                      </p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </li>-->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import UserService from "@/helpers/api/user.service";
import WhatsNew from "./_whats_new.vue"
import Lang from "./_lang.vue"

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["theme/navbarMinimize", "theme/toggleConfigurator"]),
    ...mapActions(["theme/toggleSidebarColor"]),

    toggleSidebar() {
      this['theme/toggleSidebarColor']("bg-white");
      this['theme/navbarMinimize']();
    },
    logout() {
      UserService.logout().then(() => {
        localStorage.removeItem('current_bot')
        localStorage.removeItem('newBotSetting')

        this.$router.go('Signin')
      })
    }
  },
  components: {
    Breadcrumbs, WhatsNew, Lang
  },
  // computed: {
  //   currentRouteName() {
  //     return this.$store.state.theme.breadcrumbs
  //
  //     // if (this.$route.name === "Reports") {
  //     //   return this.$route.params.type_report
  //     // }
  //     // return this.$route.name;
  //   }
  // }
};
</script>
