import Api from '@/helpers/api/api';
// import store from '@/store/store'
import globalHelper from '@/helpers/globalHelper'
import i18n from '@/i18n'

let current_bot = null
if (localStorage.getItem('current_bot') === 'undefined') {
    localStorage.removeItem('current_bot')
}
if (localStorage.getItem('current_bot')) {
    current_bot = JSON.parse(localStorage.getItem('current_bot'));
}



if (current_bot && current_bot.key === undefined) {
    current_bot = null;
    localStorage.removeItem('current_bot')
}
const demobot = {
    'key': '64d1063eebf32',
    'bot_name': 'demo_bot'
}
export default {
    namespaced: true,
    state: {
        init: false,
        csrf: null,
        processing: null,
        user: null,
        current_bot: current_bot,
        user_bots: [],
        currency: 'RUB',
        tariffs: null,
        reload_dashboard: 0
    },
    actions: {
        reInit({ commit, getters, dispatch }) {
            if (getters.getProcessing !== null) {
                return getters.getProcessing
            }
            commit('setInit', false)

            return dispatch('init')
        },
        init({ commit, getters, state }) {
            if (getters.getProcessing !== null) {
                return getters.getProcessing
            }
            if (getters.getInit) {
                return {
                    user: getters.getUser
                }
            }

            const request = {
                method: 'get',
                path: 'site/init',
            }

            const promise = Api.sendRequest(request).then(response => {
                // commit('setCsrf', response.data.data.csrf)
                commit('setProcessing', null);

                if (response.user === null) {
                    commit('setUser', false)
                    commit('setUserBots', [])
                    commit('setTariff', null)
                } else {
                    commit('setUser', response.user)
                    commit('setTariff', response.tariffs)
                    commit('setUserBots', response.bots)

                    i18n.global.locale = response.user.locale

                    if (state.current_bot) {
                        if (response.bots[state.current_bot.key] === undefined) {
                            commit('setCurrentBot', null)
                        } else {
                            commit('setCurrentBot', response.bots[state.current_bot.key])
                        }
                    }
                    if (Object.keys(response.bots).length && !state.current_bot) {
                        commit('setCurrentBot', response.bots[Object.keys(response.bots)[0]])
                    }

                    if (response.notify) {
                        response.notify.forEach(val => {
                            globalHelper.showNotify(val.msg, val.type)
                        })
                    }
                }


                // const loggeduser = localStorage.getItem('user');
                // if (!loggeduser && response.user !== null) {
                //     store.dispatch('authfack/saveUserData', response.data.data.user)
                // }
                // if (response.user !== null) {
                //     dispatch('onlyAuthInit', response.data.data)
                // }

                commit('setInit', true)
                return response
            }).catch((error) => {
                throw error
            })

            commit('setProcessing', promise)
            return promise
        }
    },
    getters: {
        getCsrf(state) {
            return state.csrf
        },
        getProcessing(state) {
            return state.processing
        },
        getUser(state) {
            return state.user
        },
        getInit(state) {
            return state.init
        },
        getHasDemobot(state) {
            return state.current_bot?.key === demobot.key
        }
    },
    mutations: {
        setInit(state, val) {
            state.init = val
        },
        setUser(state, user) {
            state.user = user
        },
        setTariff(state, tariff) {
            state.tariffs = tariff
        },
        setUserBots(state, bots) {
            state.user_bots = bots
        },
        setCurrentBot(state, bot) {
            state.current_bot = bot
            if (!bot) {
                localStorage.removeItem('current_bot')
            } else {
                localStorage.setItem('current_bot', JSON.stringify(bot))
            }
        },
        setDemobot(state) {
            // console.log(1)
            state.current_bot = demobot
            localStorage.setItem('current_bot', JSON.stringify(demobot))
        },
        resetDemobot(state) {
            if (state.user_bots.length !== 0) {
                state.current_bot = state.user_bots[0]
                localStorage.setItem('current_bot', JSON.stringify(state.current_bot))
            } else {
                state.current_bot = null
                localStorage.removeItem('current_bot')
            }

        },
        setCsrf(state, csrf) {
            state.csrf = csrf;
        },
        setProcessing(state, status) {
            state.processing = status
        },
        reloadDashboard(state) {
            state.reload_dashboard++
        }
    }

}

