<!--
=========================================================
* Vue Argon Dashboard 2 - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <div v-show="this.$store.state.theme.loadingApp" >loading</div>

  <div v-if="showDemoMsg" class="bg-warning text-center z-2000 cursor-pointer position-fixed w-100" @click="stopDemoBot">
    <span class="text-white me-2 font-weight-bold">{{$t('General.DemoNotify')}}</span>
    <span class="text-white small">({{$t('General.DemoNotifyText')}})</span>
  </div>

  <div v-show="!this.$store.state.theme.loadingApp">
    <div
        v-show="this.$store.state.theme.layout === 'landing'"
        class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
    ></div>
    <sidenav
        :custom_class="this.$store.state.theme.mcolor"
        v-if="this.$store.state.theme.showSidenav"
    />
    <!--        :class="[-->
    <!--      this.$store.state.theme.isTransparent,-->
    <!--      this.$store.state.theme.isRTL ? 'fixed-end' : 'fixed-start'-->
    <!--    ]"-->
    <main
        class="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
    >
      <!-- nav -->
      <navbar
          :class="[navClasses]"
          :textWhite="
        this.$store.state.theme.isAbsolute ? 'text-white opacity-8' : 'text-white'
      "
          :minNav="this['theme/navbarMinimize']"
          v-if="this.$store.state.theme.showNavbar"
      />
      <router-view />
      <app-footer v-show="this.$store.state.theme.showFooter" />
<!--      <configurator-->
<!--          :toggle="this['theme/toggleConfigurator']"-->
<!--          :class="[-->
<!--        this.$store.state.theme.showConfig ? 'show' : '',-->
<!--        this.$store.state.theme.hideConfigButton ? 'd-none' : ''-->
<!--      ]"-->
<!--      />-->
    </main>
  </div>
</template>
<script>
import Sidenav from "./examples/Sidenav";
// import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
// import {ElNotification} from "element-plus";
// import { Tooltip } from 'bootstrap'

export default {
  name: "App",
  components: {
    Sidenav,
    // Configurator,
    Navbar,
    AppFooter
  },
  data: function() {
    return {
      showDemoMsg: false
    }
  },
  methods: {
    ...mapMutations(["theme/toggleConfigurator", "theme/navbarMinimize"]),
    demobotOn: function () {
      this.showDemoMsg = true
      // ElNotification.closeAll()
      //
      // ElNotification({
      //   // title: 'HTML String',
      //   dangerouslyUseHTMLString: true,
      //   message: '<span class="text-white">' + this.$t('General.DemoNotify') + '</span><p class="text-white small">' + this.$t('General.DemoNotifyText') + '</p>',
      //   duration: 0,
      //   // showClose: false,
      //   // type: 'warning',
      //   customClass: 'bg-gradient-danger border-0 text-white',
      //   icon: null,
      //   position: 'bottom-left',
      //   onClose: this.stopDemoBot,
      //   onClick: this.stopDemoBot
      // })
    },
    demobotOff: function () {
      this.showDemoMsg = false
      // ElNotification.closeAll()
    },
    stopDemoBot: function() {
      this.$store.commit('init/resetDemobot')
      this.$router.push({name: "Home"})
    },
    initDemoBOt: async function() {
      this.$store.commit('init/setDemobot')
    },
    trackingChat: function () {
      if (!this.user) {
        return;
      }

      // eslint-disable-next-line no-undef
      if ($zoho?.salesiq?.visitor) {
        this.applySettingsTrack()
      } else {
        // eslint-disable-next-line no-undef
        $zoho.salesiq.ready= this.applySettingsTrack
      }
    },
    applySettingsTrack: function() {
        // eslint-disable-next-line no-undef
        // $zoho.salesiq.reset();
        // eslint-disable-next-line no-undef
        $zoho.salesiq.visitor.id(this.user.key);
        // eslint-disable-next-line no-undef
        $zoho.salesiq.visitor.email(this.user.email); // this.user.email

        let bots = {}
        const bot_count = Object.keys(this.$store.state.init.user_bots).length
        if (bot_count) { //  && bot_count < 4
          let t = 0;
          for (let i in this.$store.state.init.user_bots) {
            if (t>=5) {
              break;
            }
            bots['b_' + this.$store.state.init.user_bots[i].key] = this.$store.state.init.user_bots[i].bot_name + '(' + this.$store.state.init.user_bots[i].status + ') '
            t++;
          }
        }

        if (bot_count > 5) {
          bots = {bot_count: bot_count }
        }

        //eslint-disable-next-line no-undef
        $zoho.salesiq.visitor.info(
             { ...this.user, ...bots }
        )
    },
    tracking: function () {
      if (!this.user) {
        return;
      }

      let tariff = 'free';
      if (this.user.tariff === 1 ) {
        tariff = 'trial'
      } else if (this.user.tariff === 2 ) {
        tariff = 'base'
      } else if (this.user.tariff === 3 ) {
        tariff = 'advance'
      } else if (this.user.tariff === 4 ) {
        tariff = 'individual'
      }

      this.$posthog.identify( this.user.key, {
        'tariff' : tariff,
        'tid': this.user.tid
      });

      if (!this.user.email) {
        return
      }

      let email = this.user.email ? this.user.email : this.user.key + '@graspil.com'



      let bots = ''
      if (Object.keys(this.$store.state.init.user_bots).length) {
        for (let i in this.$store.state.init.user_bots) {
          bots += this.$store.state.init.user_bots[i].bot_name + '(' + this.$store.state.init.user_bots[i].status + '), '
        }
      }
      document.addEventListener("DOMContentLoaded", () => {
        window.sendinblue.identify(email, {
          'KEY': this.user.key,
          'TARIFF' : tariff,
          'TID': this.user.tid,
          'TARIFF_EXPIRATION_DATE': this.user.expiration_date,
          'BOTS': bots
        });

        window.sib.email_id = email;
      })

    }
  },
  computed: {
    navClasses() {
      return {
        "position-sticky bg-white left-auto top-2 z-index-sticky":
          this.$store.state.theme.isNavFixed && !this.$store.state.theme.darkMode,
        "position-sticky bg-default left-auto top-2 z-index-sticky":
          this.$store.state.theme.isNavFixed && this.$store.state.theme.darkMode,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state.theme
          .isAbsolute,
        "px-0 mx-4": !this.$store.state.theme.isAbsolute
      };
    },
    hasDemobot: function () {
      return this.$store.getters['init/getHasDemobot']
    },
    user: function () {
      return this.$store.state.init.user
    }
  },
  watch: {
    hasDemobot: function (val) {
      if (val) {
        this.demobotOn()
      } else {
        this.demobotOff()
      }
    },
    user: function () {
      this.tracking()
      this.trackingChat()
    }
  },
  created() {
    if (this.hasDemobot) {
      this.demobotOn()
    } else {
      this.demobotOff()
    }
  },
  mounted() {

    this.tracking()
    this.trackingChat()
    // window._paq.push(['trackPageView']);

    // new Tooltip(document.body, {
    //   selector: "[data-bs-toggle='tooltip']",
    // })
  },
  beforeMount() {
    // this.$store.state.theme.isTransparent = "bg-transparent";
  }
};
</script>