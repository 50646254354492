import { createI18n } from 'vue-i18n'
// import VueCookies from 'vue-cookies'

export const defaultLocale = 'en'
export const availableLang = ['en', 'ru']
let currentLang = defaultLocale


let params = (new URL(document.location)).searchParams;
if (params.get("lang")) {
  currentLang = params.get("lang")
}

// const cookLang = VueCookies.get('lang')
//
// if (cookLang) {
//   currentLang = cookLang
// }

if (!availableLang.includes(currentLang)) {
  currentLang = 'en'
}


function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = createI18n({
  locale: currentLang,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

export async function switchLang(locale) {
  if (i18n.global.locale === locale) {
    return false
  }
  if (!availableLang.includes(locale)) {
    return false
  }
  i18n.global.locale = locale
  // const router = useRouter()
  // await router.replace({params: {locale: locale}})

}

export default i18n
