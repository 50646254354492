export default {
  namespaced: true,
  state: {
    loadingApp: true,
    hideConfigButton: false,
    isPinned: false,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    isTransparent: "bg-transparent",
    breadcrumbs: "",
    h1: ""
  },

  mutations: {
    /**
     * send object like: {name: 'breadcrumbs', val: this.$route.params.type_report}
     * Where name it is name property in state
     * @param state
     * @param name
     * @param val
     * @private
     */
    __set(state, {name, val}) {
      state[name] = val
    },
    setIsAbsolute(state, val) {
      state.isAbsolute = val
    },
    // setHideConfigButton(state, val) {
    //
    // },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (!state.isPinned) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
      // if (sidenav_show.classList.contains("g-sidenav-hidden")) {
      //   sidenav_show.classList.remove("g-sidenav-hidden");
      //   sidenav_show.classList.add("g-sidenav-pinned");
      //   state.isPinned = true;
      // } else {
      //   sidenav_show.classList.add("g-sidenav-hidden");
      //   sidenav_show.classList.remove("g-sidenav-pinned");
      //   state.isPinned = false;
      // }
    },
    navbarHidden(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (state.isPinned && !sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    }
  },

  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    }
  },

  getters: {}
}
