<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="`${this.$store.state.theme.isRTL ? '' : ' me-sm-6'} ${this.$store.state.theme.isNavFixed ? 'text-dark' : 'text-white' }`"
    >
      <li class="text-sm breadcrumb-item">
        <a
          v-if="this.$store.state.theme.isRTL"
          class="opacity-5 ps-2"
          href="#"
          :class="this.$store.state.theme.isNavFixed ? 'text-dark' : 'text-white'"
          >لوحات القيادة</a
        >

        <router-link v-else to="/"
                     :class="this.$store.state.theme.isNavFixed ? 'text-dark' : 'text-white'"
                     class="opacity-8">{{$t("General.Main")}}</router-link>
      </li>
      <li
          v-for = "(item, i) in this.$store.state.theme.breadcrumbs"
          :key = i
        class="text-sm breadcrumb-item active"
        :class="this.$store.state.theme.isNavFixed ? 'text-dark' : 'text-white'"
        aria-current="page"
      >

        <router-link v-if="item.href" :to="item.href"
                     :class="this.$store.state.theme.isNavFixed ? 'text-dark' : 'text-white'"
                     class="opacity-8 link-classic">{{item.label}}</router-link>
        <span v-else >{{item.label}}</span>
      </li>


    </ol>
    <h6 class="mb-0 font-weight-bolder" :class="this.$store.state.theme.isNavFixed ? 'text-dark' : 'text-white'">
      {{ $store.state.theme.h1 }}
    </h6>
  </nav>
</template>

<script>
export default {
  name: "breadcrumbs",
  props: {
    // currentPage: {
    //   required: true
    // },
    textWhite: {
      type: String
    }
  },
  // computed: {
  //     currentRouteName() {
  //       return this.$store.state.theme.h1
  //     }
  //   }
};
</script>
