import Api from "./api"
import {ElNotification} from "element-plus";
import i18n from "@/i18n";

export default {
    sendRequest: async function (fetchData) {
        return Api.sendRequest(fetchData).catch(async data => {
            if (data?.server?.answer === 403) {

                let store = await import("@/store/store")
                await store.default.dispatch('init/reInit')


                import("@/router").then(router => {
                    router.default.push({name: "Home"})
                })

                ElNotification({
                    title: i18n.global.t('General.Error'),
                    message: i18n.global.t('General.ErrorsText.AccessDenied'),
                    type: 'error',
                })

            }
            // return data
            return new Promise((resolve, reject) => {
                reject(data)
            });
        })
    }
}