<template>
  <li
      class="nav-item dropdown d-flex align-items-center mr-4"
      :class="this.$store.state.theme.isRTL ? 'ps-2' : 'pe-2'"
  >
    <a
        :href="linkFotTelegram[$i18n.locale]"
        class="p-0 nav-link text-white"
        :class="[drawer ? 'show' : '']"
        id="dropdownMenuButton"
        aria-expanded="false"
        target="_blank"
    >
      <i class="cursor-pointer far fa-paper-plane"></i>
      {{$t('Menu.WhatsNew')}}
    </a>
  </li>
  <el-drawer v-model="drawer" title="Что нового?" >
    <div
        v-for="(data, i) in content"
        :key = "i"
    >
      <div class="d-flex justify-content-between align-content-center mb-2 ">
        <h6 class="small mb-0">{{data.node.title}}</h6>
        <span class="text-normal text-muted text-monospace text-xs wnew_date">{{data.node.date}}</span>
      </div>
      <div class="wnew_content" v-html="data.node.content.html"></div>
      <hr class="mt-5 mb-5 horizontal dark" />
    </div>
  </el-drawer>
</template>

<script>
import { gql } from 'graphql-request';

export default {
  data() {
    return {
      drawer: false,
      content: null,
      linkFotTelegram: {
        ru: "https://t.me/+MgHFkkaapGowNmMy",
        en: "https://t.me/+BBNcRlwQwmA1OTky"
      }
      // content: [
      //   {
      //     title: "UTM",
      //     date: "12.08.2023",
      //     data: "<p class=\"small\" >Стал доступен UTM отчет, очень полезный отчет который может показать поведение пользователей из определенного источника. Считать окупаемость рекламы стало немного удобней.</p>" +
      //         "<p class=\"small\" >Чтобы UTM отчет корректно работал не забывайте добавлять метки ко всех публикуемым ссылкам на бот. Так как метки в телеграм отличаются от классических, мы добавили конструктор ссылок для вашего удобства.</p>"
      //   },
      //   {
      //     title: "Новые способы подключения",
      //     date: "09.08.2023",
      //     data: "<p class=\"small\" >Теперь если ваш бот использует метод <b>getUpdate</b> его тоже можно подключить просто заменив домен в запросе с <b>api.telegram.org</b> на наш.</p>" +
      //         "<p class=\"small\" >С платформой можно взаимодействовать и через <b>API</b> вне зависимости от способа работы вашего бота. Для этого всего лишь нужно добавить один https запрос к нашей системе, без необходимости покрывать запросами весь код.</p>" +
      //         "<p class=\"small\" >Если у вас есть вопросы по подключению, дайте нам знать и мы постараемся помочь.</p>"
      //   },
      //   {
      //     title: "Мы рады представить вам наш новый сервис.",
      //     date: "04.08.2023",
      //     data: "<p class=\"small\" >На данный момент у нас представлены только самые базовые отчеты, однако, мы активно работаем и выпускаем обновления пару раз в неделю.</p>" +
      //         "<p class=\"small\" >Мы уже агрегируем многие данные и если нужного вам отчета еще нет в системе, есть смысл подключаться уже сейчас, в этом случае когда отчет появиться он уже будет наполнен данными.</p>" +
      //         "<p class=\"small\">Если у вас возникла необходимость в каких-либо специфичных отчетах, пожалуйста, сообщите нам об этом. Ваша обратная связь важна для нас на данном этапе разработки.</p>"
      //   }
      // ]
    }
  },
  watch: {
    drawer: function (val) {
      if (val && !this.content) {
        this.getPosts()
      }
    }
  },
  methods: {
    getPosts: async function () {
      const data = await this.$hygraph.request(
          gql`
        query getPosts($first: Int, $skip: Int, $stage: Stage!, $where: PostWhereInput, $orderBy: PostOrderByInput) {
          page: postsConnection(
            first: $first
            skip: $skip
            stage: $stage
            where: $where
            orderBy: $orderBy
          ) {
            edges {
              node {
                date
                slug
                title
                excerpt
                author {
                  name
                }
                categories(first: 500) {
                  title
                  slug
                  locale
                }
                coverImage {
                  url
                  altText
                }
                content {
                  html
                }
              }
            }
            aggregate {
              count
            }
          }
        }
      `,
          {
            stage: "PUBLISHED",
            locales: [this.$i18n.locale],
            orderBy: "date_DESC",
            where: {"AND":[ {"lang": this.$i18n.locale, "categories_some":{"slug": "whats-new"}} ] }
          }
      );

      this.errors = data.errors;
      this.loading = false;
      this.content = data.page.edges;
    }
  }
}
</script>

<style scoped>
  .wnew_date {
    line-height: 1.625;
  }
</style>