export default {
    namespaced: true,
    state: {
        user: {},
        all_messages: [],
        rendered_messages: {},
        message_modifications: {},
        messages_on_second: {}
    },
    actions: {
    },
    getters: {
    },
    mutations: {
        /**
         * {name: 'properties', val: 'value'}
         * @param state
         * @param name
         * @param val
         * @private
         */
        // __set(state, {name, val}) {
        //     state[name] = val
        //     const data = {}
        //     for (let i in state) {
        //         if (i === 'token') {
        //             continue
        //         }
        //         data[i] = state[i]
        //     }
        // },
        setUser(state, {user}) {
            state.user = user
        },
        addMessages(state, data) {
            state.all_messages = data.concat(state.all_messages)
        },
        renderedMsg(state, {message_id}) {
            state.rendered_messages[message_id] = true
        },
        addMsgOnSecond(state, { msg }) {
            const timestamp = Math.floor(msg.date_obj.getTime() / 1000);

            state.messages_on_second = state.messages_on_second || {};
            state.messages_on_second[timestamp] = state.messages_on_second[timestamp] || {};
            state.messages_on_second[timestamp][msg.way] = state.messages_on_second[timestamp][msg.way] || {};
            state.messages_on_second[timestamp][msg.way][msg.type] = true;
        },
        addModification(state, {message_id, data}) {
            if (state.message_modifications[message_id]) {
                state.message_modifications[message_id].push(data)
            } else {
                state.message_modifications[message_id] = [data]
            }
        },
        resetData(state) {
            state.all_messages = []
            state.rendered_messages = {}
            state.message_modifications = {}
        }
    }

}

