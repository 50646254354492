const defaultSettings = {
    filters: {},
    atr_model_type: "atr_model_last"
}

export default {
    namespaced: true,
    state: { ...defaultSettings }, // newBotSetting ??
    actions: {
    },
    getters: {
        filters: function (state) {
            return state.filters
        }
    },
    mutations: {
        /**
         * {name: 'properties', val: 'value'}
         * @param state
         * @param name
         * @param val
         * @private
         */
        __set(state, {name, val}) {
            state[name] = val
            const data = {}
            for (let i in state) {
                if (i === 'token') {
                    continue
                }
                data[i] = state[i]
            }
            // const data = {
            //     typePlatform: state.typePlatform,
            //     platform: state.platform,
            //     overPlatform: state.overPlatform,
            //     riskOk: state.riskOk
            // }
            // localStorage.setItem(nameVariableForLS, JSON.stringify(data))
        },
        updateFilter(state, {name, val}) {
          state.filters =  { ...state.filters, ...{[name]: val} }
        },
        resetData(state) {
            for (let i in state) {
                state[i] = defaultSettings[i] ?? null
            }
        }
    }

}

