import { ElNotification } from 'element-plus'
import {isArray, isObject} from "chart.js/helpers";
import i18n from "@/i18n";

export default {
    copyTextToClipboard: function (text) {
        navigator.clipboard.writeText(text)
            .then(() => {
                ElNotification({
                    title: 'Success',
                    message: i18n.global.t('General.DataCopied'),
                    type: 'success',
                })
            })
    },
    showNotify: async function( msg, type = 'info', params = {}, duration = 4500 ) {
        ElNotification({
            title: i18n.global.t('TypeNotify.' + type),
            message: i18n.global.t(msg, params),
            type: type,
            duration: duration
        })
    },
    showErrors: async function (errors) {
        if (errors === undefined || errors === '' || !errors.length) {
            this.showNotify('Notify.UnexpectedError', 'error', {}, 10000)
            return
        }
        if (isArray(errors)) {
            errors.forEach(error => {

                if (isObject(error)) {
                    for (let i in error) {
                        this.showNotify(i, 'error', error[i], 10000)
                    }
                } else {
                    this.showNotify(error, 'error', {}, 10000)
                }
            })
            // return
        }
        if (typeof errors === 'string') {
            this.showNotify(errors, 'error', {}, 10000)
        } else {
            alert(errors)
        }

        // for (let i in errors) {
        //     errors[i].forEach(error => {
        //         ElNotification({
        //             title: 'Error',
        //             message: i18n.global.t(error),
        //             type: 'error',
        //         })
        //     })
        // }

    },
}