<template>
  <div class="dropdown ">
    <a class="px-0 nav-link font-weight-bold text-white text-uppercase" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <span>
                 {{$i18n.locale}}
                </span>
    </a>

    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
      <li>
        <a src="#" class="dropdown-item" @click="changeLang('ru')">
          RU
        </a>
      </li>
      <li>
        <a src="#" class="dropdown-item" @click="changeLang('en')">
          EN
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import userService from "@/helpers/api/user.service";

export default {
  name: "_lang",
  data() {
    return {};
  },
  methods: {
    changeLang: function (lang) {
      this.$i18n.locale = lang
      return userService.setLang({ lang: lang}).then((data) => {
        this.$store.commit('init/setUser', data.user)
      })
    }
  }
}
</script>

<style scoped>

</style>