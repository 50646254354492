export default {
    namespaced: true,
    state: {
        bot: null,
        profile: null
    },
    actions: {
    },
    getters: {
    },
    mutations: {
        /**
         * {name: 'properties', val: 'value'}
         * @param state
         * @param name
         * @param val
         * @private
         */
        __set(state, {name, val}) {
            state[name] = val
            const data = {}
            for (let i in state) {
                if (i === 'token') {
                    continue
                }
                data[i] = state[i]
            }
        },
        setBotSettings(state, settings) {
            state.bot.settings = settings
        },
        setFakeCommand(state, command) {
            state.bot.fakeCommand = command
        },
        setHasToken(state, val) {
            state.bot.has_token = val
        }
    }

}

